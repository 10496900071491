<template>
    <form id="downloadForm" class="form" novalidate="true">
        <!-- Name -->
        <div class="field">
            <span class="validation">
                <div v-show="errors.has(inputIds.name)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.name) && fields[inputIds.name] && fields[inputIds.name].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.name"> {{ localization.label.name }} </label>
            <input class="input" type="text" :id="inputIds.name" v-model="name" v-validate="'required|min:3'" :name="inputIds.name">        
        </div>
        <!-- Password -->
        <div v-if="hasPassword" class="field">
            <span class="validation">
                <div v-show="errors.has(inputIds.password)" class="tooltip invalid">
                    <i class="fas fa-times-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.invalid }}</span>
                </div>
                <div v-show="!errors.has(inputIds.password) && fields[inputIds.password] && fields[inputIds.password].dirty" class="tooltip valid">
                    <i class="fas fa-check-circle"></i>
                    <span class="tooltiptext">{{ localization.validationMessage.valid }}</span>
                </div>
            </span>
            <label class="label" :for="inputIds.password"> {{ localization.label.password }} </label>
            <span class="input-group-text" @click="showPassword = !showPassword">
                <i class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
            </span>
            <div class="field has-addons" style="padding-left: 0px;">
                <input v-if="showPassword" type="text" class="input" :id="inputIds.password" v-model="password" v-validate="{ required: hasPassword }" :name="inputIds.password"/>
                <input v-else type="password" class="input" :id="inputIds.password" v-model="password" v-validate="{ required: hasPassword }" :name="inputIds.password">
            </div>
        </div>
    </form>  
</template>

<script>
import Localize from '@localize';

export default {
    props: {
        hasPassword: {
            type: Boolean,
            default: false
        },
        showPassword: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            inputIds: {
                password: 'password',
                name: 'name'
            },
            password: '',
            name: ''
        }
    },
    computed: {
        localization () {
            return Localize.downloadForm()
        },
        formValid () {
            return Object.keys(this.fields).every(field => {
                return this.fields[field] && this.fields[field].valid
            })
        }
    },
    methods: {
        isFormValid () {
            this.$validator.validateAll()
            return this.formValid
        },
        createFormData () {
            if (!this.formValid)
                throw new Error('form is not valid')

            var formData = new FormData()

            if (this.hasPassword) {
                formData.append('password', this.password)
            }
            formData.append('name', this.name)

            return formData
        }
    }
}
</script>
