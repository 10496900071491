<template>
    <card>
        <h1 slot="title" >DefShare - Download</h1>
        <div v-if="showLoading" slot="content" class="loader_spinner"></div>
        <!-- NotFoundView -->
        <div v-if="showNotFound" slot="content">
            <file-not-found />
        </div>
        <button v-if="showNotFound" slot="footer" class="button_submit" v-on:click="uploadForm()">
            {{ localization.label.uploadFile }}
        </button>
        <!-- Downloading -->
        <button v-if="showDownloading" slot="footer" class="button_submit button-disabled" disabled="disabled">
            {{ localization.label.downloading }}
        </button>
        <!-- NormalView -->
        <div v-show="showNormal" slot="content-right">
            <download-form ref="form" :has-password="passwordRequired" />
            <re-captcha ref="recaptcha" />
        </div>
        <div v-if="showNormal" slot="content-left" class="center">
            <p class="label-info">
                <span class="label-info-header">{{ localization.label.name }}: </span>
                {{ fileName }}
            </p>
            <p class="label-info">
                <span class="label-info-header">{{ localization.label.size }}: </span> 
                {{ fileLength }}
            </p>
        </div>
        <button v-if="showNormal" slot="footer" class="button_submit" v-on:click="submitForm()">
            {{ localization.label.download }}
        </button>
    </card>
</template>

<script>
import Card from '@components/Card'
import Api from '@services/Api'
import Localize from '@localize'
import DownloadForm from '@components/DownloadForm'
import ReCaptcha from '@components/ReCaptcha'
import FileNotFound from '@components/FileNotFound'
import Common from '@services/Common'

import { EventBus, Names } from '@/EventBus'

export default {
    components: {
        Card,
        ReCaptcha,
        DownloadForm,
        FileNotFound
    },
    computed: {
        localization () {
            return Localize.downloadView()
        },
        showLoading () {
            return this.loading && !this.fileNotFound && !this.downloading
        },
        showNormal () {
            return !this.loading && !this.fileNotFound && !this.downloading
        },
        showNotFound () {
            return !this.loading && this.fileNotFound && !this.downloading
        },
        showDownloading () {
            return !this.loading && !this.fileNotFound && this.downloading
        }
    },
    data () {
        return {
            loading: true,
            fileNotFound: false,
            downloading: false,

            fileRequestId: this.$route.query.FileRequestId || this.$route.query.fileRequestId || this.$route.query.filerequestid,
            fileId: this.$route.query.FileId || this.$route.query.fileId || this.$route.query.fileid,
            fileName: '',
            fileLength: '',

            passwordRequired: false,
        }
    },
    mounted () {
        this.fileInfo()
    },
    methods: {
        uploadForm () {
            this.$router.replace('/')
        },
        async fileInfo () {
            try {
                await Api.serviceInfo()
                let response = await Api.fileInfo(this.fileId, this.fileRequestId)
                response = response.data

                this.fileName = response.fileName
                this.fileLength = Common.getReadableFileSizeString(response.length)
                this.passwordRequired = response.isPasswordRequired
            } catch (error) {
                this.fileNotFound = true

                console.error(error)
                console.error(error.response)
            } finally {
                this.loading = false
            }
        },
        async submitForm () {
            if (this.$refs.form.isFormValid() && this.$refs.recaptcha.isValid())
            {
                try {                    
                    this.downloading = true
                    await this.download()
                } catch (error) {
                    this.downloading = false

                    console.error(error)
                    console.error(error.response)
                    this.$refs.recaptcha.reset()
                    this.notify(error.response)
                }
            }
        },
        async download () {
            let formData = this.$refs.form.createFormData()
            formData.append('fileRequestId', this.fileRequestId)
            formData.append('fileId', this.fileId)
            formData.append('token', this.$refs.recaptcha.getToken())

            await Api.serviceInfo()
            await Api.download(formData)
        },
        notify (response) {
            if (response != null && response.status == 401) {
                EventBus.$emit(Names.ShowError, this.localization.message.passwordInvalid)    
            } else {
                EventBus.$emit(Names.ShowError, this.localization.message.somethingWentWrong)
            }
        }
    }
}
</script>

<style lang="sass">
.center
    display: flex
    flex-direction: column
    justify-content: center
    height: 100%
</style>
